<template>
  <b-form class="mt-3" id="resDetForm" v-if="item" @submit.stop.prevent>
    <DetailsContent :item="item" :relItems="relItems" />
    <b-form-row class="justify-content-end">
      <b-col cols="*">
        <ButtonSet type="b-form-row" :useCols="true" suffix="Det" :id="0"
            :item="item" :saveResult="true" class="justify-content-end"
            :showCancel="true" :showReject="true" :showSubmit="true"
            :beforeShow="true" beforeVariant="primary" beforeSuffix="Back" @beforeClick="onBack"
            :afterShow="item.reservationUuid4!==null" afterVariant="primary" afterSuffix="Rsv" :afterTo="`/reservations/`+item.reservationUuid4">
          <template #before>
            <Icon type="b" /> {{ $t('btn.b') }}
          </template>
          <template #after>
            <Icon type="ln" /> {{ $t('tra.brs') }}
          </template>
        </ButtonSet>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import DetailsContent from '@/components/transactions/DetailsContent';
import ButtonSet from '@/components/transactions/ButtonSet';

export default {
  data() {
    return {
    };
  },
  computed: {
    item() {
      return this.$store.state.oTraDetails;
    },
    relItems() {
      return this.$store.state.oTraRelated ?? [];
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1); // return from where we came from
    },
  },
  components: {
    DetailsContent,
    ButtonSet,
    Icon,
  },
}
</script>
