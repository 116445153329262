<template>
  <div v-if="item!=null">
    <b-form-row class="text-center mb-3">
      <b-col cols="12">
        <i18n path="tra.th" tag="h5">
          <template #name>
            <b>{{ getHeader }}</b>
          </template>
          <template #pill>
            <b-badge pill :variant="getBadgeV">
              {{ getBadgeT }}
            </b-badge>
          </template>
        </i18n>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('traM.fty')" label-for="traDetType" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-receipt />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetType" :value="getType" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('traM.fda')" label-for="traDetDate" label-class="mr-1 pl-sm-4" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-calendar />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetDate" :value="getDateTime" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('traM.fam')" label-for="traDetAmount" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-credit-card />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetAmount" :value="getMoney(item.amount, item.currency)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="*" sm="6">
        <b-form-group v-if="isPayment" :label="$t('traM.fat')" label-for="traDetTip" label-class="mr-1 pl-sm-4" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-cash />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetTip" :value="getMoney(item.tip || 0, item.currency)" />
          </b-input-group>
        </b-form-group>
        <b-form-group v-else-if="isPaymentFee" :label="$t('traM.fre')" label-for="traDetRef" label-class="mr-1 pl-sm-4" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-card-text />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetRef" :value="getRef" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row" v-if="isPayment">
      <b-col cols="*" sm="6">
        <b-form-group v-if="isUser" :label="$t('traM.fre')" label-for="traDetRef" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-card-text />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetRef" :value="getRef" />
          </b-input-group>
        </b-form-group>
        <b-form-group v-else-if="relItems.length > 0" :label="$t('tra.hxcs')" label-for="traDetFee" label-class="mr-1" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-credit-card />
              </b-input-group-text>
            </template>
            <b-input type="text" readonly id="traDetFee" :value="getMoney(relItems[0].amount, item.currency)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { BIconCreditCard, BIconCash, BIconCardText, BIconCalendar, BIconReceipt } from 'bootstrap-vue';
import { utcToZonedTime } from 'date-fns-tz';

export default {
  computed: {
    getDateTime() {
      return this.$d(utcToZonedTime(this.item.createdAt+'Z', this.tz),'as');
    },
    getBadgeV() {
      if (['PREPARED'].includes(this.item.state)) return 'secondary';
      else if (['COMPLETED','CANCELED', 'REJECTED'].includes(this.item.state)) return 'primary';
      else if (['INVESTIGATED','ABORTED'].includes(this.item.state)) return 'danger';
      else return 'warning';
    },
    getBadgeT() {
      if (['CREATED'].includes(this.item.state)) return this.$t('traM.s.'+'new');
      else return this.$t('traM.s.'+this.item.state.toLowerCase());
    },
    getRef() {
      return this.item.ref;
    },
    getType() {
        return this.$t('traM.ty.'+this.item.type);
    },
    getHeader() {
      if (this.item.type === 'PAYMENT') {
        return this.isUser ? this.item.receiverName : this.item.ref;
      } else {
        return this.$t('traM.ty.'+this.item.type);
      }
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    isPayment() {
      return  this.item.type === 'PAYMENT';
    },
    isPaymentFee() {
      return  this.item.type === 'PAYMENTFEE';
    },
  },
  methods: {
    getMoney(a, c) {
      if (a == null) return '';
      return this.$n(a/100, { key: 'c', currency: c });
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    relItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BIconCreditCard,
    BIconCash,
    BIconCardText,
    BIconCalendar,
    BIconReceipt,
  },
}
</script>

<style scoped>
  h5>.badge {
    vertical-align:middle;
    margin-top: -0.25em;
  }
</style>
